<template>
    <div class="content-wall">
        <div class="content" :class="diyiti ? 'show' : ''">
            <div class="question-content">
                <Label></Label>
                <!-- <Tips v-if="tips"></Tips> -->
                <div class="container" @scroll="divScroll">
                    <div class="flip" :class="fanye ? 'on' : 'off'">
                        <single-question
                            :questionList="questionList"
                            :fanye="fanye"
                            v-if="questionList.answerType == '1'"
                            :resultData="questionList"
                            @isSingleAnswered="getIsSingleAnswered"
                            :questionIndex="firstIndex"
                        ></single-question>
                    </div>
                </div>
            </div>
            <div class="bottom-btn">
                <a-button
                    :type="this.questionList.questionId == firstQuestionId ? 'dashed' : 'danger'"
                    :disabled="returnDisabled || this.questionList.questionId == firstQuestionId"
                    @click="getPreQuestion"
                    shape="round"
                    :block="true"
                >
                    回退
                </a-button>

                <a-button
                    type="primary"
                    @click="getNextQuestion"
                    :disabled="isDisabled"
                    shape="round"
                    :block="true"
                >
                    继续
                </a-button>
            </div>
            <a-modal
                v-model="visible"
                on-ok="handleOk"
                @cancel="handleCancel"
                :closable="false"
                dialogClass="question-model"
                width="6.44rem"
            >
                <template slot="footer">
                    <div class="footer-main">
                        <div class="OFF" @click="handleCancel"> 返回 </div>
                        <div class="OK" @click="handleOk">推送法规</div>
                    </div>
                </template>
                <div class="model-main">
                    <div class="model-top">
                        <img
                            class="model-picture"
                            src="../../../assets/img/question/model.png"
                            alt=""
                        />
                        <div class="model-top-font">
                            <span>提示</span>
                            <img src="../../../assets/img/question/border.png" alt="" />
                        </div>
                    </div>
                    <div class="model-font">
                        <span>请确认答案真实准确，推送法规后，</span>
                        <span> 将无法返回修改答案。</span>
                    </div>
                </div>
            </a-modal>
        </div>
    </div>
</template>

<script>
    import Label from '@/components/PC/questionType/Label.vue'
    import * as Server from '@/api/server.js'
    import SingleQuestion from '@/components/PC/questionType/SingleQuestion'

    export default {
        name: 'Questions',
        data() {
            return {
                visible: false,
                confirmLoading: false,
                resultId: '',
                moduleId: '',
                userQuestionId: '',
                result: {}, //查询接口返回数据
                groupQuestionsList: [], //将一页一题和一页多题整合到当前页答题数组
                newQuestionsData: {}, //点击下一题所需参数（带答案的对象回传）
                questionCode: '', //判断是否是第一题，是，禁用上一题按钮
                firstIndex: 1, //记录题号
                questionRecord: [],
                isDisabled: false,
                firstQuestionId: '',
                firstQuestionCode: '',
                fanye: false,
                diyiti: false,
                answeringProgress: 0,
                questionList: {},
                returnDisabled: false,
            }
        },
        components: {
            SingleQuestion,
            Label,
            // Tips,
        },
        mounted() {
            this.diyiti = true
            this.search()
            setTimeout(() => {
                let testDiv = document.getElementsByClassName('container')[0]
                let divScrollTop = testDiv.scrollTop
                let divClientHeight = testDiv.clientHeight
                let divScrollHeight = testDiv.scrollHeight
                if (divScrollTop + divClientHeight < divScrollHeight) {
                    this.$store.commit('setTips', true)
                } else {
                    this.$store.commit('setTips', false)
                }
            }, 300)
        },
        computed: {
            tips() {
                return this.$store.state.tips
            },
        },
        watch: {
            fanye() {
                setTimeout(() => {
                    document.getElementsByClassName('container')[0].scrollTo(0, 0)
                }, 550)
                setTimeout(() => {
                    let testDiv = document.getElementsByClassName('container')[0]
                    let divScrollTop = testDiv.scrollTop
                    let divClientHeight = testDiv.clientHeight
                    let divScrollHeight = testDiv.scrollHeight
                    if (divScrollTop + divClientHeight < divScrollHeight) {
                        this.$store.commit('setTips', true)
                    } else {
                        this.$store.commit('setTips', false)
                    }
                }, 1100)
            },
        },
        methods: {
            getIsSingleAnswered(data) {
                if (data) {
                    setTimeout(() => {
                        this.getNextQuestion()
                    },300)
                }
            },
            divScroll() {
                let testDiv = document.getElementsByClassName('container')[0]
                let divScrollTop = testDiv.scrollTop
                let divClientHeight = testDiv.clientHeight
                let divScrollHeight = testDiv.scrollHeight
                if (divScrollTop + divClientHeight < divScrollHeight) {
                    this.$store.commit('setTips', true)
                } else {
                    this.$store.commit('setTips', false)
                }
            },
            //获取答卷
            async search() {
                //请求数据
                let data = {
                    method: 'POST',
                    url: 'f/question/getFirstQuestion',
                    config: {
                        data: { moduleId: this.$route.query.moduleId },
                    },
                    loading: true,
                }
                let dataSource = await Server.Axios(data)
                if (dataSource.data.returnCode == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    return
                }
                this.questionList = dataSource.data.returnData
                this.firstQuestionId = dataSource.data.returnData.questionId
            },
            //跳转下一题
            async getNextQuestion() {
                this.isDisabled = true
                if (
                    this.questionList.answerList.filter((el) => {
                        return el.checked
                    })[0].answerId
                ) {
                    this.isDisabled = false
                }
                let sendData = {
                    questionId: this.questionList.questionId,
                    questionSort: this.questionList.questionSort,
                    questionType: this.questionList.questionType,
                    answerType: this.questionList.answerType,
                    userResultId: this.questionList.userResultId,
                    answerId: [
                        this.questionList.answerList.filter((el) => {
                            return el.checked
                        })[0].answerId,
                    ],
                    userQuestionId: this.userQuestionId,
                }
                let data = {
                    method: 'post',
                    url: 'f/question/getNextQuestion',
                    config: {
                        data: sendData,
                    },
                    loading: false,
                }
                let dataSource = await Server.Axios(data)
                if (dataSource.data.returnCode == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    return
                }
                if (dataSource.data.returnData.hasNext == '1') {
                    this.firstIndex++
                    this.questionList = dataSource.data.returnData
                    this.userQuestionId = dataSource.data.returnData.userQuestionId
                } else {
                    this.resultId = dataSource.data.returnData.resultId
                    this.userQuestionId = dataSource.data.returnData.userQuestionId
                    this.showModal()
                }
            },

            //跳转上一题
            async getPreQuestion() {
                this.isDisabled = false
                this.returnDisabled = true
                let sendData = {
                    questionId: this.questionList.questionId,
                    userResultId: this.questionList.userResultId,
                    beforeQuestionId: this.questionList.beforeQuestionId,
                }
                let data = {
                    method: 'post',
                    url: 'f/question/beforeQuestion',
                    config: {
                        data: sendData,
                    },
                    loading: false,
                }
                let dataSource = await Server.Axios(data)

                if (dataSource.data.returnCode == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    return
                } else {
                    this.firstIndex--
                    this.questionList = dataSource.data.returnData
                    this.userQuestionId = dataSource.data.returnData.userQuestionId
                    this.returnDisabled = false
                }
            },
            // 显示弹框
            showModal() {
                this.visible = true
            },
            //生成意见书
            handleOk() {
                this.$router.push({
                    name: 'Result',
                    query: {
                        resultId: this.resultId,
                    },
                })
            },
            handleCancel() {
                this.visible = false
                this.isDisabled = false
            },
        },
    }
</script>

<style lang="scss" type="text/scss" scoped>
    .content {
        width: 12.2rem;
        height: 88vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        /*justify-content: center;*/
        overflow: hidden;
    }

    .content-wall {
        height: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
    }

    .question-content {
        width: 100%;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0.8rem 0.6rem 0.3rem;
        border-radius: 20px;
        background-color: #358cf3;
        position: relative;
        margin-top: 0.25rem;

        .temp-div {
            width: 90%;
            margin: 0 auto;
            min-height: 200px;
            border: 1px solid #ccc;
            text-align: left;
        }
    }

    .progress-box {
        width: 696px;
        margin: -10px auto 30px;
        height: 20px;
        margin-left: 192px;
        position: relative;
        p {
            text-align: center;
            color: #fff;
            letter-spacing: 2px;
            margin-top: 30px;
        }
        .prograss-icon {
            position: absolute;
            top: 5px;
            transition: All 0.1s ease-in-out;
        }
    }

    .container {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
    }

    .container::-webkit-scrollbar {
        display: none;
    }

    .ti-wall {
        margin-bottom: 0.4rem;
    }

    .ti-wall:last-child {
        margin-bottom: 0px;
    }

    .bottom-btn {
        display: flex;
        position: relative;
        bottom: -25px;

        .ant-btn {
            width: 2.6rem;
            height: 0.64rem;
            font-size: 0.24rem;
            margin: 0 0.2rem;
            letter-spacing: 0.03rem;
        }
    }

    .footer-main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 0.74rem;

        .OK {
            width: 50%;
            text-align: center;
            height: 0.74rem;
            background-color: #358cf3;
            border-radius: 0 0 0.1rem 0;
            line-height: 0.74rem;
            color: #fff;
            font-size: 0.24rem;
            letter-spacing: 0.02rem;
            cursor: pointer;
        }

        .OFF {
            width: 50%;
            text-align: center;
            height: 0.74rem;
            background-color: #eaf3fe;
            border-radius: 0 0 0 0.1rem;
            line-height: 0.74rem;
            color: #358cf3;
            font-size: 0.24rem;
            letter-spacing: 0.02rem;
            cursor: pointer;
        }
    }

    .model-main {
        display: flex;
        flex-direction: column;

        .model-top {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .model-picture {
                width: 1.77rem;
                height: 1.22rem;
                margin-top: 0.5rem;
            }

            .model-top-font {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-top: 0.29rem;

                span {
                    font-size: 0.3rem;
                    font-weight: bold;
                    color: #333333;
                    line-height: 0.26rem;
                }

                img {
                    width: 1.33rem;
                    height: 0.24rem;
                    margin-top: -0.1rem;
                }
            }
        }

        .model-font {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 0.2rem;

            span {
                font-size: 0.24rem;
                color: #555555;
                line-height: 0.36rem;
            }
        }
    }
</style>
