<template>
    <div class="single-question-content">
        <div class="question-and-answer">
            <div class="question-title">
                <div class="question-title-font">
                    <span>单选</span>
                    <div class="sub-title">{{ questionIndex }}.{{ resultData.questionText }}</div>
                </div>
                <div v-if="resultData.remark" class="remark">{{ resultData.remark }}</div>
            </div>
            <div class="answer-list">
                <div class="answer-list-question">
                    <div
                        :class="item.checked ? 'answer-list-li-active' : 'answer-list-li'"
                        v-for="(item, index) in resultData.answerList"
                        :key="index"
                    >
                        <a-radio
                            :style="radioStyle"
                            v-show="item.display == 'true'"
                            :checked="item.checked"
                            :value="item.answerId"
                            :disabled="fanye"
                            @change="onChange"
                        >
                            <span class="answerText">{{ item.answerText }}</span>
                        </a-radio>
                        <a-popover placement="right" v-if="item.remark">
                            <template slot="content">
                                <div>{{ item.remark }}</div>
                            </template>
                            <div class="tishi-wall">?</div>
                        </a-popover>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SingleQuestion',
        props: ['resultData', 'questionIndex', 'fanye'],
        data() {
            return {
                value: '1',
                radioStyle: {
                    display: 'block',
                    color: '#333333',
                },
            }
        },
        methods: {
            onChange(e) {
                this.resultData.isAnswered = true
                this.resultData.answerList.forEach((el) => {
                    el['checked'] = el.answerId == e.target.value
                })
                this.resultData.answerList.splice(0, 0)
                this.$emit('isSingleAnswered', true)
            },
        },
    }
</script>

<style lang="less" type="text/less" scoped>
    .single-question-content {
        height: 100%;
        overflow: hidden;
    }
</style>
